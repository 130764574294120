export const trimDescription = (
  description: string,
  isMobile: boolean
): string => {
  const sentences = description.match(/[^.!?]*[.!?]/g);
  const descriptionCharLimit = isMobile ? 150 : 500;

  if (!sentences || sentences.length === 0) {
    return description;
  }

  const firstSentence = sentences[0];
  const secondSentence = sentences[1] || "";

  if ((firstSentence + secondSentence).length > descriptionCharLimit) {
    return firstSentence.trim();
  } else {
    return firstSentence + " " + secondSentence.trim();
  }
};

export const convertMinutesToHoursAndMinutesString = (
  totalMinutes: number
): string => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  let result = "";
  if (hours > 0) {
    result += `${hours}hr${hours > 1 ? "s" : ""}`;
  }
  if (minutes > 0) {
    result += `${hours > 0 ? " " : ""}${minutes}min${minutes > 1 ? "s" : ""}`;
  }

  return result || "0min";
};
