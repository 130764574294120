// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCq_Z8dnltbhoZumVDZkR2MFRS9AWC4BDI",
  authDomain: "cinema-viewer1.firebaseapp.com",
  projectId: "cinema-viewer1",
  storageBucket: "cinema-viewer1.appspot.com",
  messagingSenderId: "864845656753",
  appId: "1:864845656753:web:5039be454c64d420a7e890",
  measurementId: "G-EBD2ELMVRD",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const functions = getFunctions(app, "europe-west2");
export const analytics = getAnalytics(app);
