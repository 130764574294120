import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Drawer, List, ListItem, ListItemText } from "@mui/material";

export const Header = () => {
  const handleRedirect = (extenstion: string) => {
    window.location.href = extenstion;
  };
  const theme = useTheme();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const toggleDrawer = (open: boolean) => () => {
    setIsDrawerOpen(open);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Box
          sx={{
            width: {
              xs: "90%",
              md: "65%",
            },
            margin: "0 auto",
          }}
        >
          <Toolbar
            sx={{
              justifyContent: { xs: "space-between", md: "space-between" },
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              alignContent: "center",
              padding: 0,
            }}
          >
            <IconButton
              size="medium"
              edge="start"
              aria-label="menu"
              sx={{
                textAlign: "center",
                color: theme.palette.primary.main,
                "&:hover": {
                  backgroundColor: "transparent",
                  color: theme.palette.primary.main,
                },
                fontFamily: "'Roboto Mono', sans-serif",
              }}
              onClick={() => handleRedirect("/")}
            >
              CinemaGuide
            </IconButton>

            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{
                display: { xs: "flex", md: "flex" }, // Show only on small screens
              }}
            >
              <MenuIcon
                sx={{ color: theme.palette.primary.main }}
                fontSize="large"
              />
            </IconButton>
          </Toolbar>
        </Box>
      </AppBar>

      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: { xs: 200, md: 300 },
            backgroundColor: theme.palette.background.default,
          },
        }}
      >
        <IconButton
          onClick={toggleDrawer(false)}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: theme.spacing(1),
            color: theme.palette.primary.main,
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
        <List>
          {[
            { listItemText: "About", redirectLink: "/about" },
            { listItemText: "Venue Select", redirectLink: "/" },
            {
              listItemText: "Request a Venue",
              redirectLink: "/request-a-venue",
            },
          ].map(({ listItemText, redirectLink }) => {
            return (
              <ListItem
                onClick={() => {
                  setIsDrawerOpen(false);
                  handleRedirect(redirectLink);
                }}
                key={`link_to_${listItemText}`}
              >
                <ListItemText
                  primary={listItemText}
                  primaryTypographyProps={{
                    sx: {
                      fontFamily: "'Roboto Mono', sans-serif",
                      fontSize: { xs: "medium", md: "large" },
                      "&:hover": {
                        cursor: "pointer",
                        textDecoration: "underline",
                      },
                    },
                  }}
                />
              </ListItem>
            );
          })}
        </List>
      </Drawer>
    </Box>
  );
};
