import * as React from "react";
import Accordion, { AccordionSlots } from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import { FilmScreeningByDate } from "../../types/types.ts";
import { Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useTheme } from "@mui/material/styles";
import { ScreeningsForOneDate } from "./ScreeningsForOneDate.tsx";

interface ScreeningsForAllDatesAccordionProps {
  screeningsByDate: FilmScreeningByDate[];
  filmTitle: string;
}

export const ScreeningsForAllDatesAccordion: React.FC<
  ScreeningsForAllDatesAccordionProps
> = ({ screeningsByDate, filmTitle }) => {
  const [expanded, setExpanded] = React.useState(false);
  const theme = useTheme();

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  // const handleAccordionDetailsClick = (e: React.MouseEvent) => {
  //   // Prevent click on IndividualScreeningInfo from closing the accordion
  //   if (!(e.target as HTMLElement).closest(".individual-screening-info")) {
  //     handleExpansion();
  //   }
  // };

  return (
    <Accordion
      expanded={expanded}
      //onChange={handleExpansion}
      slots={{ transition: Fade as AccordionSlots["transition"] }}
      slotProps={{ transition: { timeout: 400 } }}
      sx={{
        backgroundColor: theme.palette.background.default,
        "& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
        "& .MuiAccordionDetails-root": {
          display: expanded ? "block" : "none",
        },
        "& .MuiAccordionSummary-root:hover, .MuiButtonBase-root:hover": {
          cursor: "default",
        },
        "&.Mui-focused": {
          backgroundColor: theme.palette.background.default,
        },
        marginTop: 0.5,
        boxShadow: "none",
      }}
    >
      <AccordionSummary
        aria-controls="panel1-content"
        id="panel1-header"
        // expandIcon={<ExpandMoreIcon />}
        sx={{
          padding: "0px",
          backgroundColor: theme.palette.background.paper,
          cursor: "default",
        }}
      >
        {expanded ? (
          <ExpandLessIcon
            sx={{ color: theme.palette.primary.main }}
            onClick={handleExpansion}
          />
        ) : (
          <ExpandMoreIcon
            sx={{ color: theme.palette.primary.main }}
            onClick={handleExpansion}
          />
        )}
        <Typography
          sx={{
            color: theme.palette.text.secondary,
            "&:hover": {
              // backgroundColor: "#f5f5f5", // Change to the desired hover background color
              color: "#FFFFFF",
            },
            cursor: "pointer",
          }}
          onClick={handleExpansion}
        >
          {" "}
          {expanded ? "HIDE ALL TIMES" : "SHOW ALL TIMES"}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          backgroundColor: theme.palette.background.paper,
          margin: "0px",
          padding: "0px",
        }}
        // onClick={handleAccordionDetailsClick}
      >
        {screeningsByDate.map(({ date, screenings }) => (
          <Box
            sx={{
              fontSize: "large",
              marginBottom: 2,
              marginLeft: 0,
              marginRight: 0,
            }}
            key={`${filmTitle}-${date}-allDatesPage`}
          >
            <Typography
              sx={{
                fontSize: "medium",
                fontWeight:
                  date === "TODAY" || date === "TOMORROW" ? "bold" : "normal",
              }}
            >
              {date}
            </Typography>

            <ScreeningsForOneDate
              screenings={screenings}
              isOnAllDatesPage={true}
            />
          </Box>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};
