import React from "react";
import { Box, Typography } from "@mui/material";

interface TextBlockProps {
  text: string;
  margin?: number;
  padding?: number;
}

export const StandardTextBlock: React.FC<TextBlockProps> = ({
  text,
  margin,
  padding,
}) => {
  return (
    <Box
      my={margin}
      p={padding}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="body1" textAlign="center">
        {text}
      </Typography>
    </Box>
  );
};
