import * as React from "react";
import Typography from "@mui/material/Typography";
import { FilmRating } from "../../types/types.ts";
import { Box, useMediaQuery } from "@mui/material";
import {
  convertMinutesToHoursAndMinutesString,
  trimDescription,
} from "../../helpers/componentHelpers.ts";

export const FilmTextInfoContainer: React.FC<{
  title: string;
  description: string;
  rating: FilmRating;
  lengthInMinutes: number | null;
}> = ({ title, description, rating, lengthInMinutes }) => {
  const isMobile = useMediaQuery("(max-width:600px)"); // Detect mobile devices

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          marginLeft: { xs: 2, md: 3 },
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "medium", md: "x-large" },
            fontFamily: "'Roboto Mono', sans-serif",
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "x-small", md: "small" },
            fontFamily: "'Roboto Condensed', sans-serif",
          }}
          color="text.secondary"
          variant="body2"
        >
          {description ? trimDescription(description, isMobile) : title}
        </Typography>{" "}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            fontSize: { xs: "x-small", md: "small" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Typography fontSize="inherit">Runtime&nbsp;</Typography>
            <Typography fontSize="inherit" color="text.secondary">
              {lengthInMinutes
                ? convertMinutesToHoursAndMinutesString(lengthInMinutes)
                : "TBC"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Typography fontSize="inherit">Rating&nbsp;</Typography>
            <Typography fontSize="inherit" color="text.secondary">
              {rating ?? "TBC"}
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};
