import React from "react";
import { Container, Box, Typography } from "@mui/material";

const arrayOfText: string[] = [
  "CinemaGuide was built to help people in the UK view what's on in Cinemas near them quickly and easily.",
  "CinemaGuide has listings for over 400 cinemas across the UK, covering all major chains and many independent cinemas.",
  "If you have any suggestions for improvements, cinemas you'd like to see added to the site, or ideas for collaboration don't hesitate to get in touch.",
  "The owner of this site is contactable via LinkedIn: https://www.linkedin.com/in/tom-benbow/ or via email: tombenbow@gmail.com",
];

export const AboutPage: React.FC = () => {
  return (
    <Container sx={{ width: { xs: "100%", md: "60%" } }}>
      <Box
        marginTop={{ xs: 3, md: 5 }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {arrayOfText.map((text: string) => {
          return (
            <Typography
              variant="body1"
              textAlign="left"
              sx={{ marginBottom: 2, fontSize: { xs: "small", md: "medium" } }}
            >
              {text}
            </Typography>
          );
        })}
      </Box>
    </Container>
  );
};
