import React from "react";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { App } from "./App.tsx";
import { createRoot } from "react-dom/client";
import { Helmet, HelmetProvider } from "react-helmet-async";

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you are using TypeScript

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet>
        <script
          defer
          src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDHmaXbKN-Zam8cBZ5dZrolVzL6pvzpp1o&libraries=places&callback=initMaps"
        />
      </Helmet>
    </HelmetProvider>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
