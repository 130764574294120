import React from "react";
import "./App.css";
import { Header } from "./components/Header/Header.tsx";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { VenueSelector } from "./pages/VenueSelector.tsx";
import { FilmListings } from "./pages/FilmListings.tsx";
import { ErrorPage } from "./pages/Error.tsx";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { AboutPage } from "./pages/About.tsx";
import { RequestAVenuePage } from "./pages/RequestAVenue.tsx";

const router = createBrowserRouter([
  {
    path: "/",
    element: <VenueSelector />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/whats-on",
    element: <FilmListings />,
  },
  {
    path: "/about",
    element: <AboutPage />,
  },
  {
    path: "/request-a-venue",
    element: <RequestAVenuePage />,
  },
]);

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});

export const App = () => {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div id="app=page">
        <Header />
        <RouterProvider router={router} />
      </div>
    </ThemeProvider>
  );
};
