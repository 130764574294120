import * as React from "react";
import { FilmScreening } from "../../types/types.ts";
import { Grid } from "@mui/material";
import { IndividualScreeningInfo } from "../IndividualScreeningInfo/IndividualScreeningInfo.tsx";

interface ScreeningsForOneDateProps {
  screenings: FilmScreening[];
  isOnAllDatesPage: boolean;
}

export const ScreeningsForOneDate: React.FC<ScreeningsForOneDateProps> = ({
  screenings,
  isOnAllDatesPage,
}) => {
  return (
    <Grid
      sx={{ marginTop: 1, marginLeft: 0, marginRight: 0 }}
      container
      gap={{ xs: 1, md: 2 }}
    >
      {screenings.map(({ venue_name, link, time }) => (
        <Grid item xs={5.8} md={3.5}>
          <IndividualScreeningInfo
            key={`${venue_name}-${time}-${
              isOnAllDatesPage ? "allDatesPage" : "oneDatePage"
            }`}
            venue_name={venue_name}
            link={link}
            time={new Date(time)}
          />
        </Grid>
      ))}
    </Grid>
  );
};
