import * as React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { CardActionArea, useTheme } from "@mui/material";
import { FilmScreening } from "../../types/types";

export const IndividualScreeningInfo: React.FC<FilmScreening> = ({
  venue_name,
  link,
  time,
}) => {
  const handleClick = () => {
    window.open(link, "_blank");
  };
  const theme = useTheme();

  const formattedTime =
    time instanceof Date
      ? `${time.getHours()}:${time.getMinutes().toString().padStart(2, "0")}`
      : "TBC";

  return (
    <Card
      variant="outlined"
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <CardActionArea
        onClick={handleClick}
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "space-between",
          padding: 1,
          "&:hover .venue-name": {
            color: theme.palette.text.primary,
          },
        }}
      >
        <Typography
          variant="h1"
          sx={{ fontSize: "medium", fontWeight: "bold" }}
        >
          {formattedTime}
        </Typography>
        <Typography
          className="venue-name"
          sx={{
            fontSize: { xs: "small", md: "small" },
            padding: { xs: 0.5, md: 1 },
            textAlign: "center",
            transition: "color 0.3s",
          }}
          color="text.secondary"
          variant="h2"
        >
          {venue_name}
        </Typography>

        <Typography
          sx={{
            fontSize: { xs: "xx-small", md: "x-small" },
            color: theme.palette.primary.main,
          }}
          variant="h3"
        >
          BOOK NOW
        </Typography>
      </CardActionArea>
    </Card>
  );
};
