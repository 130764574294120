import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {
  AllScreeningsOfOneFilm,
  ScreeningsOfOneFilmOnOneDate,
} from "../../types/types.ts";
import { FilmTile } from "../FilmTile/FilmTile.tsx";

export const AllFilmsOnOneTab: React.FC<
  | {
      films: ScreeningsOfOneFilmOnOneDate[];
      isForAllDates: false;
    }
  | {
      films: AllScreeningsOfOneFilm[];
      isForAllDates: true;
    }
> = ({ films, isForAllDates }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        padding: 0,
        margin: 0,
        marginTop: { xs: 1, md: 2 },
        width: "100%",
      }}
    >
      <Stack
        spacing={{ xs: 2, md: 5 }}
        sx={{ width: "100%", margin: "0px", padding: "0px" }}
      >
        {films.map(
          ({
            title,
            description,
            screenings,
            rating,
            image_link,
            lengthInMinutes,
          }) => (
            <FilmTile
              key={`${title}_all_dates`}
              title={title}
              description={description ? description : ""}
              image_link={image_link}
              screenings={screenings}
              rating={rating}
              isForAllDates={isForAllDates}
              lengthInMinutes={lengthInMinutes}
            />
          )
        )}
      </Stack>
    </Box>
  );
};
