import * as React from "react";
import {
  FilmRating,
  FilmScreening,
  FilmScreeningByDate,
} from "../../types/types.ts";
import { Box, useTheme } from "@mui/material";
import { ScreeningsForOneDate } from "../Screenings/ScreeningsForOneDate.tsx";
import { FilmTextInfoContainer } from "./FilmTextInfoContainer.tsx";
import { ScreeningsForAllDatesAccordion } from "../Screenings/ScreeningsForAllDatesAccordion.tsx";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

const imageBoxSx = {
  minWidth: { xs: "110px", md: "140px" }, // Responsive width
  maxWidth: { xs: "110px", md: "140px" },
  height: { xs: "165px", md: "210px" }, // Responsive height
  boxShadow: {
    xs: "0px 0px 10px 5px rgba(255, 255, 255, 0.1)", // Smaller shadow on mobile
    md: "0px 0px 20px 10px rgba(255, 255, 255, 0.1)", // Larger shadow on desktop
  },
  marginTop: { xs: "3px", md: "7px" },
  marginBottom: { xs: "3px", md: "5px" },
  borderRadius: "2px",
};

export const FilmTile: React.FC<
  | {
      title: string;
      description: string;
      screenings: FilmScreening[];
      image_link: string | null;
      rating: FilmRating;
      isForAllDates: false;
      lengthInMinutes: number | null;
    }
  | {
      title: string;
      description: string;
      screenings: FilmScreeningByDate[];
      image_link: string | null;
      rating: FilmRating;
      isForAllDates: true;
      lengthInMinutes: number | null;
    }
> = ({
  title,
  description,
  screenings,
  image_link,
  rating,
  isForAllDates,
  lengthInMinutes,
}) => {
  const theme = useTheme();
  const [isImageError, setIsImageError] = React.useState(false);

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingBottom: { xs: 3, md: 5 },
          borderBottom: `2px solid ${theme.palette.divider}`,
          maxWidth: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            minHeight: { xs: "165px", md: "210px" },
          }}
        >
          {image_link && !isImageError ? (
            <Box
              component="img"
              src={image_link}
              alt={`Poster for ${title}`}
              sx={imageBoxSx}
              onError={() => setIsImageError(true)}
            />
          ) : (
            <Box
              sx={{
                ...imageBoxSx,
                display: "flex",
                alignItems: "center",
              }}
            >
              <QuestionMarkIcon
                sx={{
                  fontSize: { xs: 110, md: 140 },
                }}
              />
            </Box>
          )}
          <FilmTextInfoContainer
            title={title}
            description={description}
            rating={rating}
            lengthInMinutes={lengthInMinutes}
          />
        </Box>
        <Box
          sx={{
            marginLeft: { xs: "0px", md: "155px" }, // Responsive width
            marginTop: 2,
          }}
        >
          <Box>
            {isForAllDates ? (
              <ScreeningsForAllDatesAccordion
                screeningsByDate={screenings}
                filmTitle={title}
              />
            ) : (
              <ScreeningsForOneDate
                screenings={screenings}
                isOnAllDatesPage={false}
              />
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
};
