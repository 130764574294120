import React from "react";
import { Container, Box, Typography } from "@mui/material";

const arrayOfText: string[] = [
  "Can't see your favourite cinema on this site? You can request it to be added by emailing tombenbow@gmail.com",
  "While I aim to add every cinema in the UK eventually, this is a long process. I will prioritise the venues I add based on user requests.",
];

export const RequestAVenuePage: React.FC = () => {
  return (
    <Container sx={{ width: { xs: "100%", md: "60%" } }}>
      <Box
        marginTop={{ xs: 3, md: 5 }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {arrayOfText.map((text: string) => {
          return (
            <Typography
              variant="body1"
              textAlign="left"
              sx={{
                marginBottom: 2,
                fontSize: { xs: "small", md: "medium" },
              }}
            >
              {text}
            </Typography>
          );
        })}
      </Box>
    </Container>
  );
};
