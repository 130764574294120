import React from "react";
import Button from "@mui/material/Button";
import { useNavigate, createSearchParams } from "react-router-dom";
import { Box } from "@mui/material";

interface GoToWhatsOnButtonProps {
  venues: string[];
  isButtonDisabled: boolean;
}

export const WhatsOnButton = ({
  venues,
  isButtonDisabled,
}: GoToWhatsOnButtonProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (venues.length > 0) {
      navigate({
        pathname: "whats-on",
        search: `?${createSearchParams({ venues })}`,
      });
    }
  };

  return (
    <Box
      my={1}
      p={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Button
        variant="contained"
        color="primary"
        disabled={isButtonDisabled}
        onClick={handleClick}
        sx={{ fontSize: "1.25rem", my: 1, p: 2 }}
      >
        View Movies
      </Button>
    </Box>
  );
};
